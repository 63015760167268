<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <v-card>
          <v-card-text>
            <v-form ref="miscForm">
              <v-row align="center" justify="center">
                <v-col cols="12" lg="4">
                  <v-text-field
                    clearable
                    outlined
                    label="Miscellaneous"
                    v-model="misc"
                    hide-details="auto"
                    class="rounded-0"
                    :error-messages="errorMsg"
                    :rules="[(v) => !!v || 'Miscellaneous is required']"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" lg="4">
                  <v-text-field
                    clearable
                    v-model="amount"
                    outlined
                    type="number"
                    min="0"
                    class="rounded-0"
                    label="Unit Amount"
                    hide-details="auto"
                    :rules="[(v) => !!v || 'Amount is required']"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" lg="4">
                  <v-btn
                    block
                    :disabled="
                      !getters_abilities.includes(
                        'save_miscellaneous_fees_access'
                      )
                    "
                    :loading="addMiscLoading"
                    @click="addMiscBtn"
                    color="primary"
                    class="subtitle-1"
                  >
                    save
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-form ref="editMiscForm">
          <v-data-table
            :headers="headers"
            :items="basketWithIndex"
            hide-default-footer
            class="elevation-2"
            item-key="id"
            :loading="isLoaded"
          >
            <template v-slot:item.amount="amount">
              <v-edit-dialog
                single-line
                @save="saveAmountChangesBtn(parseFloat(amount.item).toFixed())"
                @open="originalAmountText = amount.item.amount"
              >
                {{ parseFloat(amount.item.amount).toFixed(2) }}
                <template v-slot:input>
                  <v-text-field
                    clearable
                    class="mt-5"
                    v-model.number="originalAmountText"
                    label="Change Amount"
                    outlined
                    type="number"
                    :rules="[(v) => !!v || 'Amount is required']"
                  ></v-text-field>
                </template>
              </v-edit-dialog>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-edit-dialog
                single-line
                v-if="
                  getters_abilities.includes('remove_miscellaneous_fees_access')
                "
              >
                <v-btn dark right large icon>
                  <v-icon dark class="red--text"> mdi-close-circle </v-icon>
                </v-btn>

                <template v-slot:input>
                  <v-card flat>
                    <v-card-text class="pa-3 ma-0">
                      Do You Want to Delete <b>{{ item.type }}</b> ?
                    </v-card-text>
                    <v-card-actions class="px-0 ma-0">
                      <v-btn
                        color="red"
                        dark
                        block
                        :disabled="
                          !getters_abilities.includes(
                            'remove_miscellaneous_fees_access'
                          )
                        "
                        @click="removeMiscBtn(item.id, item)"
                      >
                        Delete
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </template>
              </v-edit-dialog>
            </template>
            <template v-slot:no-data>
              <span class="subheading font-weight-bold">
                No Miscellaneous Available
              </span>
            </template>
          </v-data-table>
        </v-form>
      </v-col>
    </v-row>

    <Response v-if="response">
      <template v-slot:header>{{ msgHeader }}</template>
      <template v-slot:body>{{ msgBody }}</template>
      <template v-slot:icon>{{ msgIcon }}</template>
    </Response>
  </v-container>
</template>
<script>
  import {
    computed,
    defineComponent,
    getCurrentInstance,
    provide,
    reactive,
    toRefs,
  } from "vue";
  import { useActions, useGetters } from "vuex-composition-helpers";
  import Response from "@/components/ActionResponse/Response";

  export default defineComponent({
    components: { Response },
    setup() {
      const vm = getCurrentInstance();

      const { getMiscFees, saveMiscAmountChanges, removeMiscType, saveNewMisc } =
        useActions([
          "getMiscFees",
          "saveMiscAmountChanges",
          "removeMiscType",
          "saveNewMisc",
        ]);

      const { getters_misc_fees, getters_abilities } = useGetters([
        "getters_misc_fees",
        "getters_abilities",
      ]);

      getMiscFees().then(() => {
        editMiscForm.isLoaded = false;
      });

      const editMiscForm = reactive({
        adminResponse: false,
        isLoaded: true,
        isadmin: true,
        adminPassword: "",
        checkAdmin: "",
        originalAmountText: "",
        errorMsg: "",
        response: false,
        miscItem: null,
        headers: [
          { text: "#", value: "index" },
          { text: "MISCELLANEOUS ", value: "type" },
          { text: "AMOUNT", value: "amount" },
          { text: "ACTIONS", value: "actions", sortable: false },
        ],
        msgHeader: "",
        msgBody: "",
        msgIcon: "",
        color: "",
      });

      const miscForm = reactive({
        misc: "",
        amount: 0,
        addMiscLoading: false,
      });

      const {
        originalAmountText,
        errorMsg,
        response,
        miscItem,
        msgHeader,
        msgBody,
        msgIcon,
        color,
      } = toRefs(editMiscForm);

      const { misc, amount, addMiscLoading } = toRefs(miscForm);

      const basketWithIndex = computed(() => {
        return getters_misc_fees.value.map((items, index) => ({
          ...items,
          index: index + 1,
        }));
      });

      const saveAmountChangesBtn = (item) => {
        response.value = false;
        if (
          vm.proxy.$refs.editMiscForm.validate() &&
          getters_abilities.value.includes("edit_miscellaneous_fees_access")
        ) {
          Object.assign(item, { originalAmountText: originalAmountText.value });
          saveMiscAmountChanges(item)
            .then(() => {
              color.value = "success";
              response.value = true;
              msgHeader.value = "Success";
              msgBody.value = `Miscellaneous Item Is Updated Successfully`;
              msgIcon.value = "mdi-close-circle";
            })
            .catch((e) => {
              switch (e.response.status) {
                case 423:
                  msgBody.value = e.response.data.message;
                  break;

                case 403:
                  msgBody.value = e.response.data.message;
                  break;

                default:
                  msgBody.value = `Try Again Later Or Call The System Administrator`;
                  break;
              }
            });
        } else {
          setTimeout(() => {
            color.value = "error";
            response.value = true;
            msgHeader.value = "Error";
            msgBody.value = `THIS ACTION IS UNAUTHORIZED`;
            msgIcon.value = "mdi-close-circle";
          });
        }
      };

      provide("color", color);

      const removeMiscBtn = (id, item) => {
        miscItem.value = item.type;

        response.value = false;
        removeMiscType(id)
          .then(() => (originalAmountText.value = ""))
          .catch(() => {
            response.value = true;
            msgHeader.value = "Error";
            msgBody.value = `${miscItem.value} cannot be deleted. is used in other component(s)`;
            msgIcon.value = "mdi-close-circle";
          });
      };

      const addMiscBtn = () => {
        if (vm.proxy.$refs.miscForm.validate()) {
          addMiscLoading.value = true;
          let basket = { type: misc.value, amount: amount.value };

          saveNewMisc(basket)
            .then(() => {
              addMiscLoading.value = false;
              vm.proxy.$refs.miscForm.reset();
            })
            .catch(() => {
              errorMsg.value =
                misc.value.charAt(0).toUpperCase() +
                misc.value.slice(1) +
                " already exists";
              addMiscLoading.value = false;
            });
        }
      };

      provide("miscType", miscItem);

      return {
        ...toRefs(editMiscForm),
        ...toRefs(miscForm),
        basketWithIndex,
        saveAmountChangesBtn,
        removeMiscBtn,
        addMiscBtn,
        getters_abilities,
      };
    },
  });
</script>
