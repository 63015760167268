var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',[_c('v-card',[_c('v-card-text',[_c('v-form',{ref:"miscForm"},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"12","lg":"4"}},[_c('v-text-field',{staticClass:"rounded-0",attrs:{"clearable":"","outlined":"","label":"Miscellaneous","hide-details":"auto","error-messages":_vm.errorMsg,"rules":[(v) => !!v || 'Miscellaneous is required']},model:{value:(_vm.misc),callback:function ($$v) {_vm.misc=$$v},expression:"misc"}})],1),_c('v-col',{attrs:{"cols":"12","lg":"4"}},[_c('v-text-field',{staticClass:"rounded-0",attrs:{"clearable":"","outlined":"","type":"number","min":"0","label":"Unit Amount","hide-details":"auto","rules":[(v) => !!v || 'Amount is required']},model:{value:(_vm.amount),callback:function ($$v) {_vm.amount=$$v},expression:"amount"}})],1),_c('v-col',{attrs:{"cols":"12","lg":"4"}},[_c('v-btn',{staticClass:"subtitle-1",attrs:{"block":"","disabled":!_vm.getters_abilities.includes(
                      'save_miscellaneous_fees_access'
                    ),"loading":_vm.addMiscLoading,"color":"primary"},on:{"click":_vm.addMiscBtn}},[_vm._v(" save ")])],1)],1)],1)],1)],1)],1)],1),_c('v-row',[_c('v-col',[_c('v-form',{ref:"editMiscForm"},[_c('v-data-table',{staticClass:"elevation-2",attrs:{"headers":_vm.headers,"items":_vm.basketWithIndex,"hide-default-footer":"","item-key":"id","loading":_vm.isLoaded},scopedSlots:_vm._u([{key:"item.amount",fn:function(amount){return [_c('v-edit-dialog',{attrs:{"single-line":""},on:{"save":function($event){_vm.saveAmountChangesBtn(parseFloat(amount.item).toFixed())},"open":function($event){_vm.originalAmountText = amount.item.amount}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-text-field',{staticClass:"mt-5",attrs:{"clearable":"","label":"Change Amount","outlined":"","type":"number","rules":[(v) => !!v || 'Amount is required']},model:{value:(_vm.originalAmountText),callback:function ($$v) {_vm.originalAmountText=_vm._n($$v)},expression:"originalAmountText"}})]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(parseFloat(amount.item.amount).toFixed(2))+" ")])]}},{key:`item.actions`,fn:function({ item }){return [(
                _vm.getters_abilities.includes('remove_miscellaneous_fees_access')
              )?_c('v-edit-dialog',{attrs:{"single-line":""},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',{staticClass:"pa-3 ma-0"},[_vm._v(" Do You Want to Delete "),_c('b',[_vm._v(_vm._s(item.type))]),_vm._v(" ? ")]),_c('v-card-actions',{staticClass:"px-0 ma-0"},[_c('v-btn',{attrs:{"color":"red","dark":"","block":"","disabled":!_vm.getters_abilities.includes(
                          'remove_miscellaneous_fees_access'
                        )},on:{"click":function($event){return _vm.removeMiscBtn(item.id, item)}}},[_vm._v(" Delete ")])],1)],1)]},proxy:true}],null,true)},[_c('v-btn',{attrs:{"dark":"","right":"","large":"","icon":""}},[_c('v-icon',{staticClass:"red--text",attrs:{"dark":""}},[_vm._v(" mdi-close-circle ")])],1)],1):_vm._e()]}},{key:"no-data",fn:function(){return [_c('span',{staticClass:"subheading font-weight-bold"},[_vm._v(" No Miscellaneous Available ")])]},proxy:true}],null,true)})],1)],1)],1),(_vm.response)?_c('Response',{scopedSlots:_vm._u([{key:"header",fn:function(){return [_vm._v(_vm._s(_vm.msgHeader))]},proxy:true},{key:"body",fn:function(){return [_vm._v(_vm._s(_vm.msgBody))]},proxy:true},{key:"icon",fn:function(){return [_vm._v(_vm._s(_vm.msgIcon))]},proxy:true}],null,false,916177833)}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }